<template>
  <v-app-bar absolute color="transparent" flat height="85" class="pa-0" >

    <span @click="home" class="d-flex mt-1" style="cursor: pointer">
      <span class="hidden-xs-only">Openstage</span>
    </span>
    <v-spacer />

    <span v-if="$vuetify.breakpoint.smAndDown">

      <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" to="/" min-height="40" text>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" to="/features" min-height="40" text>
        <v-icon>mdi-group</v-icon>
      </v-btn>

      <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" to="/ticketing" min-height="40" text>
        <v-icon>mdi-ticket</v-icon>
      </v-btn>

      <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" to="/pricing" min-height="40" text>
        <v-icon>mdi-currency-usd-circle-outline</v-icon>
      </v-btn>

      <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.smOnly" @click="utils.dashboard()" min-height="40" text>
        <v-icon>mdi-ticket-account</v-icon>
        <!--ATS - <span v-if="!$auth.isAuthenticated">Login</span> -->
      </v-btn>
    
    </span>
    <span v-else>

    <v-btn to="/" min-height="48" text>
      <v-icon>mdi-home</v-icon>
      <span>Home</span>
    </v-btn>

    <v-btn to="/features" min-height="48" min-width="40" text>
      <v-icon>mdi-group</v-icon>
      <span>Features</span>
    </v-btn>

    <v-btn to="/ticketing" min-height="48" min-width="40" text>
      <v-icon>mdi-ticket</v-icon>
      <span>Tickets</span>
    </v-btn>

    <v-btn to="/pricing" min-height="48" min-width="40" text>
      <v-icon>mdi-currency-usd-circle-outline</v-icon>
      <span>Pricing</span>
    </v-btn>

    <!-- ATS - <v-btn min-height="48" @click="utils.dashboard()" min-width="40" text>
      <v-icon>mdi-ticket-account</v-icon>
      <span v-if="$auth.isAuthenticated">Box Office</span>
      <span v-else>Login</span>
    </v-btn> -->
    </span>

  </v-app-bar>
</template>

<script>
  import Utils from '@/services/Utils.js';
  export default {
    name: 'PagesCoreAppBar',

    methods: {
      home() {
        window.location = "/";
      }
    },
    computed: {
      utils: function () {
        return Utils;
      },
    },
  }
</script>
